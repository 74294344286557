module.exports = {
  apiUrl,
  rootUrl
}

function apiUrl () {
  return window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port + '/api/' : '/api/')
}

function rootUrl () {
  return window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port + '/' : '/')
}
