import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export const router = new Router({
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'login',
      component: () => import(/* webpackChunkName: "about" */ '../components/Login.vue')
    },
    {
      path: '/forgetPassword',
      name: 'forgetPassword',
      component: () => import(/* webpackChunkName: "about" */ '../components/ForgetPassword.vue')
    },
    { path: '*', redirect: '/' }
  ]
})
