import Vue from 'vue'
import vuetify from './_vuetify'
import App from './app/App.vue'
import { router, i18n } from './_helpers'
import VueCookies from 'vue-cookies'
import 'vuetify/dist/vuetify.min.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import { extend } from 'vee-validate'
import { required, min } from 'vee-validate/dist/rules'

extend('required', { ...required })
extend('min', { ...min })

Vue.use(VueCookies)
Vue.config.productionTip = false

export const dashboardMixIn = {
  methods: {
    $translatedErrorMessages: function (errors, name) {
      if (errors && Object.keys(errors).length > 0) {
        for (let [key] of Object.entries(errors)) {
          return this.$t(`msg.${name}_error_${key}`.toString().toLowerCase())
        }
      }
    }
  },
  created: function () {
    let pageTitle = ''
    if (this.$cookies.get('standard_dashboard_assessment_name') && this.$cookies.get('standard_dashboard_assessment_name').trim() !== '') {
      pageTitle = this.$cookies.get('standard_dashboard_assessment_name')
    }
    document.title = pageTitle
  }
}

Vue.mixin(dashboardMixIn)

new Vue({
  vuetify,
  i18n,
  router,
  render: h => h(App),
  components: { App },
  template: '<App/>',
  data: {
    themeColor: 'blue'
  }
}).$mount('#app')
